import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getElementName } from "../../helpers/helpers";
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function RoleInformation(props) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    product: yup.string().required(t("App.validationMessages.required")),
    role: yup.string().required(t("App.validationMessages.required")),
    description: yup.string().required(t("App.validationMessages.required")),
    status: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    // register,
    handleSubmit,
    control,
    // getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const initialFormState = {
    idProduct: "",
    product: "",
    role: "",
    description: "",
    status: "",
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.onClose(open);
  };

  const nextStep = () => {
    props.nextStep(1);
  };

  const [roleInfo, setRoleInfo] = useState(initialFormState);
  props.pullDataInfo(roleInfo);

  /**
   * Use Effect para el responsive
   */
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 400);
      setIsScreenSmall(window.innerWidth < 1000);
    };
    handleResize();
 
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    const roleInfo = props.roleInfo;
    setValue("idProduct", roleInfo.idProduct ? roleInfo.idProduct : "");
    setValue("product", roleInfo.product ? roleInfo.product : "");
    setValue("role", roleInfo.role ? roleInfo.role : "");
    setValue("description", roleInfo.description ? roleInfo.description : "");
    setValue("status", roleInfo.status ? roleInfo.status : "");
  }, []);

  const [productName, setProductName] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const handleInformation = async (data, e) => {
    try {
      const obj = {
        idProduct: data.product,
        product: productName,
        role: data.role,
        description: data.description,
        status: data.status,
        clientType: checkBoxValue ? "SI" : "NO",
      };


      setRoleInfo(obj);
      props.pullDataInfo(obj);

      nextStep();
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <Grid marginTop={3}>
      <p className="heading__text-grey">{t("App.createRole.information")}</p>

      <form onSubmit={handleSubmit(handleInformation)} name="form" id="form">
        <Grid 
          container
          className="modalEditRoles__form-box"
          direction={"column"}
        >
          <Grid 
            container
            columnSpacing={5}
            rowSpacing={2}
            flexDirection={"row"} 
          >
          <Grid item lg={3.8} xl={3.8} sm={11} xs={12}>
          <FormControl fullWidth>
            <InputLabel className="modalCreateOptions__form__inputLabel ">
              {t("App.createRole.product")}
            </InputLabel>

            <Controller
              control={control}
              defaultValue=""
              name="product"
              render={({
                field: { onChange, onBlur, value, name, ref },
                formState,
              }) => (
                <Select
                  value={value}
                  labelId="labelProduct"
                  label={t("App.createRole.product")}
                  className={
                    errors.product
                      ? "modalCreateRoles__form-input--error"
                      : "modalCreateRoles__form-description"
                  }
                  fullWidth
                  onChange={onChange}
                  onClick={(e) => setProductName(getElementName(e.target))}
                >
                  <MenuItem disabled value="">
                    <em>{t("App.createRole.product")}</em>
                  </MenuItem>
                  {props.activeProducts.map((item) => {
                    return (
                      <MenuItem
                        sx={{
                          display: item.selected ? "none" : "",
                        }}
                        key={item.value}
                        value={item.value}
                        name={item.label}
                      >
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />

            <FormHelperText
              sx={{
                margin: "-10px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.product ? errors.product.message : ""}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item lg={3.8} xl={3.8} sm={11} xs={12}>
          <Controller
            defaultValue=""
            control={control}
            name="role"
            render={({
              field: { onChange, onBlur, value, name, ref },
              formState,
            }) => (
              <TextField
                className={
                  errors.role
                    ? "modalCreateRoles__form-input--error"
                    : "modalCreateRoles__form-description"
                }
                fullWidth
                sx={{
                  input: {
                    marginTop: "2px",
                    padding: "8px 4px 7px 14px !important"
                  },
                }}
                helperText={errors.role ? errors.role.message : ""}
                InputLabelProps={{
                  style: {
                    margin: "-5px 0 0 -10px",
                    padding: "0 0 0 5px",
                    color: "#8c8d9d",
                    fontSize: 13,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "-5px 0 0 0",
                    color: "#e52900",
                  },
                }}
                id={name}
                name={name}
                label={t("App.createRole.role")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
              />
            )}
          />
        </Grid>

        <Grid item lg={3.8} xl={3.8} sm={11} xs={12}>
          <FormControl fullWidth>
            <InputLabel className="modalCreateOptions__form__inputLabel ">
              {t("App.createRole.status")}
            </InputLabel>

            <Controller
              control={control}
              defaultValue=""
              name="status"
              render={({
                field: { onChange, onBlur, value, name, ref },
                formState,
              }) => (
                <Select
                  value={value}
                  labelId="labelStatus"
                  label={t("App.createRole.status")}
                  className={
                    errors.status
                      ? "modalCreateRoles__form-input--error"
                      : "modalCreateRoles__form-description"
                  }
                  fullWidth
                    onChange={onChange}
                >
                  <MenuItem disabled value="">
                    <em>{t("App.createRole.status")}</em>
                  </MenuItem>
                  <MenuItem value={1}>{t("App.createRole.active")}</MenuItem>
                  <MenuItem value={0}>{t("App.createRole.inactive")}</MenuItem>
                </Select>
              )}
            />

            <FormHelperText
              sx={{
                margin: "-10px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.status ? errors.status.message : ""}
            </FormHelperText>
          </FormControl>
          </Grid>

        <Grid lg={7.7} xl={7.7} sm={11} xs={12} item>
          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({
              field: { onChange, onBlur, value, name, ref },
              formState,
            }) => (
              <TextField
                className={
                  errors.description
                    ? "modalCreateRoles__form-description--error"
                    : "modalCreateRoles__form-description"
                }
                fullWidth
                sx={{
                  input: {
                    marginTop: "2px",
                    padding: "8px 4px 7px 14px !important",
                  },
                }}
                helperText={
                  errors.description ? errors.description.message : ""
                }
                InputLabelProps={{
                  style: {
                    margin: "-5px 0 0 -10px",
                    padding: "0 0 0 5px",
                    color: "#8c8d9d",
                    fontSize: 13,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "-5px 0 0 0px",
                    color: "#e52900",
                  },
                }}
                id={name}
                name={name}
                label={t("App.createRole.description")}
                onChange={onChange}
                onBlur={onBlur} // notify when input is touched/blur
                value={value}
                inputRef={ref} // send input ref, so we can focus on input when error appear
              />
            )}
          />
      </Grid>
      </Grid>
          <Grid item container paddingTop={3} alignItems={"center"}>
            <Grid item>
              <FormGroup
                style={{
                  padding: "0 !important",
                }}
              >
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#543ab4 !important",
                      fontFamily: "Roboto !important",
                      fontWeight: "100 !important",
                      cursor: "default",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        color: "#543ab4 !important",
                      }}
                      className="modalPreviewMenu__form-checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        setCheckBoxValue(e.target.checked);
                      }}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                    />
                  }
                  label={
                  <p className="label__text-black">
                     {t("App.listRoles.clientType")}
                  </p>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item  >
      <Tooltip
          title={
          <span
            style={{
              display: "block", // Asegura que el tooltip se comporte correctamente
              textAlign: "left",
              fontSize: isMobile ? "11px" : isScreenSmall ? "14px" : "11.5px",
              margin: 0, // Elimina márgenes de <span>
              padding: 0, // Elimina paddings adicionales
              lineHeight: "normal", // Controla el espacio entre líneas
              maxWidth: isMobile
                ? "260px !important"
                : isScreenSmall
                ? "340px"
                : "700px", // Ajuste dinámico de ancho
            }}
          >
          <p
              style={{
                margin: 0,
                padding: 0,
              }}
          >
                  {t("App.createRole.tooltipText")}
          </p>
          </span>
            }
            placement={isMobile ? "bottom" : "right-end"}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [
                      isMobile ? 0 : isScreenSmall ? 32 : 6, // Ajuste horizontal
                      isMobile ? -10 : isScreenSmall ? -108 : -18, // Ajuste vertical
                    ],
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: isMobile ? 0 : 2, // Ajusta el espacio para evitar desbordes
                  },
                },
              ],
            }}
          >
        <InfoOutlinedIcon
            sx={{
              fontSize: "22px",
              color: "#543AB4",
              cursor: "pointer",
            }}
          />
        </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            gap={2}
            className="modalCreateRoles__form__actions" 
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: { xl: "row", lg: "row", sm: "row", xs: "column-reverse" },
            }}
          >
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={12}>
              <Button 
                className="btn__filled__gradient" 
                fullWidth
                onClick={handleClose}>
                {t("App.createRole.cancelButton")}
              </Button>
            </Grid>

            <Grid item  xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={12}> 
              <Button
                fullWidth
                autoFocus
                disabled={
                  watch("role") &&
                  watch("description") &&
                  watch("status") !== "" &&
                  watch("product")
                    ? false
                    : true
                }
                type="submit"
                className="btn__filled__blue"
              >
                {t("App.createRole.continueButton")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
