import React, { useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider } from "react-hook-form";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import RoleInformation from "../../components/common/roleInformation";
import RoleMenu from "../../components/common/roleMenu";
import { CreateRole } from "../../services/admin/roleServices";
import { getUser } from "../../pages/userSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CODES } from "../../consts/codes";
import { GetMenuLevel1 } from "../../services/admin/adminServices";

const ModalCreateRoles = (props) => {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle
        sx={{ m: 0, paddingTop: "2rem", paddingLeft: "0rem" }}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.primary[500],
            }}
          >
            <CloseIcon sx={{ color: "#543ab4"}}/>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const userInfo = useSelector(getUser);
  const userData = {
    nameUser: `${userInfo.firstName} ${userInfo.lastName}`,
  };

  const [open, setOpen] = React.useState(false);

  const [activeProducts, setActiveProducts] = useState([]);

  const handleClickOpen = () => {
    async function fetchDataActiveProduct() {
      try {
        if (Object.keys(userInfo).length > 0) {
          const activeProductsService = await GetMenuLevel1();

          if (
            activeProductsService.status === CODES.COD_RESPONSE_HTTP_OK &&
            activeProductsService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
          ) {
            const products = activeProductsService.data.responseMessage.map(
              (product) => {
                return {
                  label: product.menuOptionName,
                  value: product.idMenuOption,
                  // selected: false,
                };
              }
            );

            setActiveProducts(products);
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }

    setOpen(true);
    fetchDataActiveProduct();
  };

  const handleClose = (data) => {
    setOpen(false);
    reset();
  };

  const handleCloses = () => {
    setOpen(false);
    reset();
  };

  const { t } = useTranslation();

  const { reset } = useForm({
    resolver: yupResolver(),
  });

  const initialFormState = {
    idProduct: "",
    product: "",
    role: "",
    description: "",
    status: "",
  };

  const [roleInfo, setRoleInfo] = useState(initialFormState);
  const pullDataInfo = (data) => {
    setRoleInfo(data);
  };

  const pullMenuInfo = (menuIds) => {
    const finalMenu = menuIds.map((idMenuOption) => {
      return idMenuOption;
    });
    return finalMenu;
  };

  /**
   * Función para crear un nuevo rol
   * @param {*} menu opción de menú seleccionada
   */
  const handleCreateRole = async (menu) => {
    try {
      const cleanMenu = await pullMenuInfo(menu);

      const obj = {
        nameRole: roleInfo.role,
        descriptionRole: roleInfo.description,
        state: Number(roleInfo.status),
        idMenuOption: roleInfo.idProduct,
        menuOptionName: roleInfo.product,
        clientType: roleInfo.clientType,
        createUser: userData.nameUser,
        lstMenuOptions: cleanMenu,
      };

      const createRoleService = await CreateRole(obj);
      if (createRoleService.status === CODES.COD_RESPONSE_HTTP_OK) {
        if (
          createRoleService.data.responseCode === CODES.COD_RESPONSE_SUCCESS
        ) {
          props.setResponseData({
            status: createRoleService.status,
            data: {
              responseCode: createRoleService.data.responseCode,
              responseMessage: "",
            },
          });
        } else {
          props.setResponseData(createRoleService);
        }
      } else {
        props.setResponseData(createRoleService);
      }
      props.openModal(true);
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }

    props.setRefreshFilters(!props.refreshFilters);
    props.setModified(!props.modified);
    handleCloses();
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <RoleInformation
            onClose={handleClose}
            pullDataInfo={pullDataInfo}
            nextStep={nextStep}
            roleInfo={roleInfo}
            userData={userData}
            setActiveStep={setActiveStep}
            activeProducts={activeProducts}
          />
        );
      case 1:
        return (
          <RoleMenu
            pullMenuInfo={pullMenuInfo}
            previusStep={previusStep}
            roleInfo={roleInfo}
            handleCreateRole={handleCreateRole}
          />
        );
      default:
        return "unknown step";
    }
  }

  const methods = useForm({
    defaultValues: {
      role: "",
      description: "",
      status: "",
    },
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const nextStep = (data) => {
    setActiveStep(data);
  };

  const previusStep = (data) => {
    setActiveStep(data);
  };

  return (
    <Grid className="modalCreateRoles__buttonContainer">
      <Button
        className="modalCreateRoles-principalButton"
        onClick={handleClickOpen}
      >
        <AddIcon sx={{ fontSize: 14 }} />
        {t("App.createRole.principalButton")}
      </Button>

      <Dialog
        onClose={handleCloses}
        aria-labelledby="title"
        open={open}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(85,115,185,0.2)",
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100%",
              maxWidth: {
                xl: "95rem",
                lg: "95rem",
                sm: "60rem",
                md: "60rem",
                xs: "40rem"
              },
              maxHeight:  {
                xl: "50rem",
                lg: "48rem",
                sm: "63rem",
                xs: "63rem"
              },
          "& .MuiDialogContent-root" : {
            overflowX: "hidden !important",
          },
              boxShadow: "none",
              borderRadius: "22px",
              padding: "2rem 1rem 0 1rem",
            },
          },
        }}
      >
        <DialogContent>
          <Box className="stepper-container">
            <Stepper
              className="modalCreateRoles-stepper"
              activeStep={activeStep}
            >
              <Step paddingLeft={0}>
                <StepLabel>
                {t("App.stepper.stepOne")}
                </StepLabel>
              </Step>

              <Step>
                <StepLabel>
                {t("App.stepper.stepTwo")}
                </StepLabel>
              </Step>
            </Stepper>
          </Box>

          <BootstrapDialogTitle
            id="title"
            className="display-large-two__primary-two"
            onClose={handleClose}
            sx={{
              textAlign: {
                xl: "left",
                lg: "left",
                sm: "left",
                xs: "center"
                }, 
                padding: "1.5rem 8px !important"
              }}
          >
            {activeStep === 0
              ? t("App.createRole.title")
              : t("App.createRole.title2")}
          </BootstrapDialogTitle>

          <FormProvider {...methods}>{getStepContent(activeStep)}</FormProvider>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ModalCreateRoles;
