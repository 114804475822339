import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { getElementName } from "../../helpers/helpers";
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function EditRoleInformation(props) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    product: yup.string().required(t("App.validationMessages.required")),
    role: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Este campo solo admite letras"),
    description: yup
      .string()
      .required(t("App.validationMessages.required"))
      .matches(/^[a-zA-Z\u00C0-\u00FF\s]+$/, "Este campo solo admite letras"),
    status: yup.string().required(t("App.validationMessages.required")),
  });

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.onClose(open);
  };

  const nextStep = () => {
    props.nextStep(1);
  };

  /**
   * Use Effect para el responsive de las pantallas
   */
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 400);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 400);
      setIsScreenSmall(window.innerWidth < 1000);
    };
    handleResize();
 
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  

  useEffect(() => {
    const roleInfo = props.currentRole;


    setValue("product", roleInfo.idMenuOption ? roleInfo.idMenuOption : "");
    setValue("role", roleInfo.nameRole ? roleInfo.nameRole : "");
    setValue(
      "description",
      roleInfo.descriptionRole ? roleInfo.descriptionRole : ""
    );
    setValue("status", roleInfo.state !== "" ? roleInfo.state : "");

    setCheckBoxValue(props.currentRole.clientType);
  }, []);

  const [productName, setProductName] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const handleInformation = async (data, e) => {
    try {
      const obj = {
        idProduct: data.product,
        product: productName || props.roleInfo.product,
        role: data.role,
        description: data.description,
        status: Number(data.status),
        clientType: checkBoxValue ? "SI" : "NO",
      };

      props.pullDataInfo(obj);
      nextStep();
    } catch (error) {
      console.log("====================================");
      console.log(error);
      console.log("====================================");
    }
  };

  return (
    <Grid sx={{margin: "2rem 1rem 0" }}>
      <p className="heading__text-grey">{t("App.createUser.information")}</p>

      <form onSubmit={handleSubmit(handleInformation)} name="form" id="form">
        <Grid className="modalEditRoles__form-box">

        <Grid 
            container
            columnSpacing={5}
            rowSpacing={2}
            flexDirection={"row"} 
          >
          <Grid item  lg={3.8} xl={3.8} sm={11} xs={12}>
          <FormControl fullWidth>
            <InputLabel className="modalCreateOptions__form__inputLabel ">
              {t("App.editRole.product")}
            </InputLabel>

            <Controller
              defaultValue=""
              control={control}
              name="product"
              render={({
                field: { onChange, onBlur, value, name, ref },
                formState,
              }) => (
                <Select
                  value={value}
                  labelId="labelProduct"
                  label={t("App.editRole.product")}
                  className={
                    errors.product
                      ? "modalEditRoles__form-input--error"
                      : "modalCreateRoles__form-description"
                  }
                  fullWidth
                  onChange={onChange}
                  onClick={(e) => setProductName(getElementName(e.target))}
                >
                  <MenuItem disabled value="">
                    <em>{t("App.createRole.product")}</em>
                  </MenuItem>
                  {props.activeProducts.map((product) => {
                    return (
                      <MenuItem
                        sx={{
                          display: product.selected ? "none" : "",
                        }}
                        key={product.value}
                        value={product.value}
                        name={product.label}
                      >
                        {product.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />

            <FormHelperText
              sx={{
                margin: "-10px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.product ? errors.product.message : ""}
            </FormHelperText>
          </FormControl>
      </Grid>

      <Grid item  lg={3.8} xl={3.8} sm={11} xs={12}>
          <Controller
            control={control}
            name="role"
            render={({
              field: { onChange, onBlur, value, name, ref, defaultValue },
              formState,
            }) => (
              <TextField
                value={value}
                className={
                  errors.role
                    ? "modalEditRoles__form-input--error"
                    : "modalCreateRoles__form-description"
                }
                sx={{
                  input: {
                    marginTop: "2px",
                    padding: "8px 4px 7px 14px !important"
                  },
                }}
                fullWidth
                helperText={errors.role ? errors.role.message : ""}
                InputLabelProps={{
                  style: {
                    margin: "-5px 0 0 -10px",
                    padding: "0 0 0 5px",
                    color: "#8c8d9d",
                    fontSize: 13,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "-5px 0 0 0",
                    color: "#e52900",
                  },
                }}
                id={name}
                name={name}
                label={t("App.editRole.role")}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
              />
            )}
          />
      </Grid>

      <Grid item lg={3.8} xl={3.8} sm={11} xs={12}>
          <FormControl fullWidth>
            <InputLabel className="modalCreateOptions__form__inputLabel ">
              {t("App.editRole.status")}
            </InputLabel>
            <Controller
              defaultValue=""
              control={control}
              name="status"
              render={({
                field: { onChange, onBlur, value, name, ref },
                formState,
              }) => (
                <Select
                  value={value}
                  labelId="labelStatus"
                  label={t("App.editRole.status")}
                  className={
                    errors.status
                      ? "modalEditRoles__form-input--error"
                      : "modalCreateRoles__form-description"
                  }
                  fullWidth
                  onChange={onChange}
                >
                  <MenuItem disabled value="">
                    <em>{t("App.editRole.status")}</em>
                  </MenuItem>
                  <MenuItem value={1}>{t("App.editRole.active")}</MenuItem>
                  <MenuItem value={0}>{t("App.editRole.inactive")}</MenuItem>
                </Select>
              )}
            />

            <FormHelperText
              sx={{
                margin: "-10px 0 0 0",
                color: "#e52900",
              }}
            >
              {errors.status ? errors.status.message : ""}
            </FormHelperText>
          </FormControl>
      </Grid>
      
      <Grid item  lg={3.8} xl={3.8} sm={11} xs={12}>
          <Controller
            control={control}
            name="description"
            render={({
              field: { onChange, onBlur, value, name, ref, defaultValue },
              formState,
            }) => (
              <TextField
                className={
                  errors.description
                    ? "modalEditRoles__form-description--error"
                    : "modalEditRoles__form-description"
                }
                sx={{
                  input: {
                    marginTop: "2px",
                    padding: "8px 4px 7px 14px !important"
                  },
                }}
                fullWidth
                helperText={
                  errors.description ? errors.description.message : ""
                }
                InputLabelProps={{
                  style: {
                    margin: "-5px 0 0 -10px",
                    padding: "0 0 0 5px",
                    color: "#8c8d9d",
                    fontSize: 13,
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    margin: "-5px 0 0 0px",
                    color: "#e52900",
                  },
                }}
                id={name}
                name={name}
                label={t("App.editRole.description")}
                onChange={onChange}
                onBlur={onBlur} // notify when input is touched/blur
                value={value}
                inputRef={ref} // send input ref, so we can focus on input when error appear
              />
            )}
          />
      </Grid>
      </Grid>
          <Grid item container paddingTop={3} alignItems="center">
            <Grid item>
              <FormGroup
                style={{
                  padding: "0 !important",
                }}
              >
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "#543ab4 !important",
                      fontFamily: "Roboto !important",
                      fontWeight: "100 !important",
                      cursor: "default",
                    },
                  }}
                  control={
                    <Checkbox
                      defaultChecked={
                        props.currentRole.clientType === "SI" ? true : false
                      }
                      disabled={props.currentRole.containsUsers ? true : false}
                      sx={{
                        color: props.currentRole.containsUsers
                          ? "#c0c1d8 !important"
                          : "553ab6 !important",
                      }}
                      className="modalPreviewMenu__form-checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => {
                        setCheckBoxValue(e.target.checked);
                      }}
                      checkedIcon={<CheckBoxOutlinedIcon />}
                      uncheckedIcon={<CheckBoxOutlineBlankIcon />}
                    />
                  }
                  label={                  
                  <p className="label__text-black">
                  {t("App.listRoles.clientType")}
                  </p>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <Tooltip
                title={
                  props.currentRole.containsUsers ? (
                    <span
                    style={{
                      display: "block", // Asegura que el tooltip se comporte correctamente
                      textAlign: "left",
                      fontSize: isMobile ? "11px" : isScreenSmall ? "14px" : "11.5px",
                      margin: 0, // Elimina márgenes de <span>
                      padding: 0, // Elimina paddings adicionales
                      lineHeight: "normal", // Controla el espacio entre líneas
                      maxWidth: isMobile
                        ? "260px !important"
                        : isScreenSmall
                        ? "340px"
                        : "700px", // Ajuste dinámico de ancho
                    }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {t("App.editRole.tooltipText")}
                        </p>
                    </span>
                  ) : (
                    <span
                        style={{
                          display: "block", // Asegura que el tooltip se comporte correctamente
                          textAlign: "left",
                          fontSize: isMobile ? "11px" : isScreenSmall ? "14px" : "11.5px",
                          margin: 0, // Elimina márgenes de <span>
                          padding: 0, // Elimina paddings adicionales
                          lineHeight: "normal", // Controla el espacio entre líneas
                          maxWidth: isMobile
                            ? "260px !important"
                            : isScreenSmall
                            ? "340px"
                            : "700px", // Ajuste dinámico de ancho
                        }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      >{t("App.createRole.tooltipText")}</p>
                    </span>
                  )
                }
                placement={isMobile ? "bottom" : "right-end"}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [
                          isMobile ? 0 : isScreenSmall ? 32 : 6, // Ajuste horizontal
                          isMobile ? -10 : isScreenSmall ? -108 : -18, // Ajuste vertical
                        ],
                      },
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        padding: isMobile ? 0 : 2, // Ajusta el espacio para evitar desbordes
                      },
                    },
                  ],
                }}
              
              >
                <InfoOutlinedIcon style={{ color: "#553AB6" }} />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            container
            gap={2}
            className="modalCreateRoles__form__actions" 
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: { xl: "row", lg: "row", sm: "row", xs: "column-reverse" },
            }}
          >
            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={12}>
              <Button 
              className="btn__filled__gradient" 
              onClick={handleClose}
              fullWidth
              >
                {t("App.createRole.cancelButton")}
              </Button>
            </Grid>

            <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={12}>
              <Button
                fullWidth
                autoFocus
                disabled={
                  watch("role") &&
                  watch("description") &&
                  watch("status") !== "" &&
                  watch("product")
                    ? false
                    : true
                }
                type="submit"
                className="btn__filled__blue"
              >
                {t("App.createRole.continueButton")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
