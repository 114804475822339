import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router";
import CardContent from "@mui/material/CardContent";
import { Doughnut, Bar } from "react-chartjs-2";
import { IconButton } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import Box from "@mui/material/Box";
import Joyride, { STATUS } from "react-joyride";
import TourIcon from "@mui/icons-material/Tour";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getUser } from "../userSlice";
import { openQlikSense } from "../../helpers/helpers";
import CustomSkeleton from "../../components/common/CustomSkeleton";
import WelcomeHome from "../../components/common/welcomeHome";
import NoInfoDashboard from "../../components/common/noInfoDashboard";
import FilterBusinessGroup from "../../components/common/Filters/businessGroup/filterWithButtons";
import {
  IndicatorsChartHome,
  ListTransmissionsTable,
} from "../../services/user/dashboard";
import ModalActiveCompanies from "../../components/common/Modals/modalActiveCompanies";
import { IndicatorsCards } from "./indicatorsCards";
import { GetIndicators } from "../../services/user/salesIndicatorsService";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);
// PARA AGREGAR UN NUEVO HOME SE DEBE IR A IndicatorsCards.jsx Y
// AGREGAR LA INFORMACIÓN DEL NUEVO HOME
export const HomeNewCharts = () => {
  /**
   * Empty chart element
   */
  const emptyChartElement = {
    idIndicator: "",
    eanProvider: "",
    country: "",
    documentType: "",
    chartType: "",
    chartDetail: "",
    field1: "",
    field2: "",
    value1: "",
    value2: "",
    lastUpdates: "",
  };

  const { t } = useTranslation();
  const chartColors = ["rgba(141, 81, 218, 1)", "rgba(252,176,69,1)"];
  const userInfo = useSelector(getUser);
  const [isLabelsEnabledBill, setIsLabelsEnabledBill] = useState(false);
  const [isLabelsEnabledPayroll, setIsLabelsEnabledPayroll] = useState(false);
  const navigate = useNavigate();
  const fullNameUser = `${userInfo.firstName} ${userInfo.lastName}`;
  const emailUser = userInfo.email;
  const [lastUpdateSales, setLastUpdateSales] = useState("");
  const [lastUpdateInventories, setLastUpdateInventories] = useState("");
  const [dataFilter, setSetDataFilter] = useState(false);

  const [dataTableInfo, setDataTableInfo] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [chartsInfo, setChartsInfo] = useState([]);

  const [selectedInformationType, setSelectedInformationType] =
    useState("sales");
  const [selectType, setSelectType] = useState(false);
  const [selectedConsultationUnit, setSelectedConsultationUnit] =
    useState("quantity");

  const [totalCatalog, setTotalCatalog] = useState("");
  const [totalCommerce, setTotalCommerce] = useState("");
  const [totalFZA, setTotalFZA] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingButtons, setIsLoadingButtons] = useState(true);

  const [isSalesSelected, setIsSalesSelected] = useState(true);
  const [isQuantitySelected, setIsQuantitySelected] = useState(true);
  const [dataBarChartMTDSales, setDataBarChartMTDSales] = useState(null);
  const [dataBarChartYTDSales, setDataBarChartYTDSales] = useState(null);
  const [dataBarChartVSSales, setDataBarChartVSSales] = useState(null);
  const [qlikLinks, setQlikLinks] = useState(
    new Map([
      ["catalog", { url: "" }],
      ["salesforce", { url: "" }],
      ["commerce", { url: "" }],
      ["transmissionAudit", { url: "" }],
    ])
  );

  const [dataBarChartMTDInventory, setDataBarChartMTDInventory] =
    useState(null);
  const [dataBarChartYTDInventory, setDataBarChartYTDInventory] =
    useState(null);
  const [dataBarChartVSInventory, setDataBarChartVSInventory] = useState(null);
  const [catalogChart, setCatalogChart] = useState(null);
  const [salesForceChart, setSalesForceChart] = useState(null);
  const [assetsChart, setAssetsChart] = useState(null);

  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [dataTableRaws, setDataTableRaws] = useState([]);
  const [dataTable, setDataTable] = useState(null);

  const [isOpenTour, setIsOpenTour] = useState(false);

  const [openActiveCommerces, setOpenActiveCommerces] = useState(false);

  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  // Home contratados
  const [contractedHomes, setContractedHomes] = useState([]);

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: "none",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            footer: {
              border: "none",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              height: "49px",
              justifyContent: "center",
              alignItems: "start",
            },
            toolButton: {
              // * Secreto para que los headers del datatable queden centrados con el contenido
              margin: "0",
              padding: "0",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              textAlign: "center",
            },
          },
        },
      },
    });
  /**
   * Función que verifica la información del usuario y redirige según los servicios disponibles.
   * @param {Object} userInfo - Información del usuario.
   */

  const verificarYRedirigir = (userInfo) => {
    if (typeof userInfo === "object") {
      const lstMenuServices = userInfo.lstMenu;
      // Verificar si lstMenuServices contiene los servicios Ventas e Inventarios
      const hasVentasInventariosService = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "Ventas e Inventarios"
      );
      // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de factura"
      const hasCenFiscalInvoice = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "CEN Fiscal Factura"
      );
      // Verificar si el servicio CEN Fiscal tiene el nivel "Indicadores de nómina"
      const hasCenFiscalPayroll = lstMenuServices.some(
        (menuOption) => menuOption.menuOptionName === "CEN Fiscal Nómina"
      );

      if (hasCenFiscalInvoice && !hasVentasInventariosService) {
        navigate("/user/homeBillCEN");
      } else if (hasCenFiscalPayroll && !hasVentasInventariosService) {
        navigate("/user/homePayrollCEN");
      }
    }
  };

  /**
   * Funcion que sirve para procesar y settear los charts para mostrar los indicadores
   * @param {obj} chartsData
   */
  const processDataAndSetCharts = (chartsData) => {
    const charts = chartsData.data.responseMessage.objectData;
    setChartsInfo(charts);
    setLastUpdateSales(chartsData.data.responseMessage.lastUpdateSales);
    setLastUpdateInventories(
      chartsData.data.responseMessage.lastUpdateInventories
    );
    if (charts.length > 0) {
      //Formateamos los charts
      const separateChartsResult = separateCharts(charts);

      //Construimos bar chart de MTD para ventas
      const buildInfoMTDResult = buildInfoBarCharts(
        separateChartsResult.mtdSales,
        "MTD"
      );

      //Construimos bar chart de MTD para inventarios
      const buildInfoMTDInventoryResult = buildInfoBarCharts(
        separateChartsResult.mtdInventories,
        "MTD"
      );

      //Construir bar chart de YTD para ventas
      const buildInfoYTDResult = buildInfoBarCharts(
        separateChartsResult.ytdSales,
        "YTD"
      );

      // Construir bar chart de YTD para inventarios
      const buildInfoYTDInventoryResult = buildInfoBarCharts(
        separateChartsResult.ytdInventories,
        "YTD"
      );

      //Construimos bart chart para Mes actual vs Mes anterior para ventas
      const buildInfoVSResult = buildInfoBarCharts(
        separateChartsResult.vsSales,
        "MES ACT vs MES ANT"
      );

      //Construimos bar chart para Mes actual vs Mes anterior para inventarios
      const buildInfoVSInventoryResult = buildInfoBarCharts(
        separateChartsResult.vsInventories,
        "MES ACT vs MES ANT"
      );

      //Construimos pie chart para Catálogo
      const buildPieChartCatalog = buildPieChartsData(
        separateChartsResult.pies.catalog
      );

      //Construimos pie chart para Fuerza de ventas
      const buildPieChartSalesForce = buildPieChartsData(
        separateChartsResult.pies.salesForce
      );

      //Construimos pie chart para comercios activos
      const buildPieChartAssets = buildPieChartsData(
        separateChartsResult.pies.assets
      );

      //Setteamos la data para visualizar los charts
      setDataBarChartMTDSales(buildInfoMTDResult);
      setDataBarChartMTDInventory(buildInfoMTDInventoryResult);
      setDataBarChartYTDSales(buildInfoYTDResult);
      setDataBarChartYTDInventory(buildInfoYTDInventoryResult);
      setDataBarChartVSSales(buildInfoVSResult);
      setDataBarChartVSInventory(buildInfoVSInventoryResult);
      setCatalogChart(buildPieChartCatalog);
      setSalesForceChart(buildPieChartSalesForce);
      setAssetsChart(buildPieChartAssets);
    }
  };

  /**
   * Funcion que sirve para settear los valores de las columnas en la tabla de transmisiones
   * @param {obj} dataTable
   * @returns columnas de la tabla
   */
  const buildDataTableColumns = (dataTable) => {
    setDataTableInfo(dataTable);
    return [
      {
        name: dataTable[0].field1,
        label: t("App.homeDashboard.client.table.columns.column1"),
        options: {
          sort: true,
        },
      },
      {
        name: dataTable[0].field2,
        label: t("App.homeDashboard.client.table.columns.column2"),
        options: {
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            const lastTransmission = (
              <div>
                <h4>{t("App.homeDashboard.client.table.columns.column2")}</h4>
                <h5 className="subheading-datatable">
                  {t("App.homeDashboard.client.table.columns.formatDate")}
                </h5>
              </div>
            );

            return lastTransmission;
          },
        },
      },
      {
        name: dataTable[0].field4,
        label: isQuantitySelected
          ? t("App.homeDashboard.client.table.columns.columnCant")
          : t("App.homeDashboard.client.table.columns.columnValue"),
        options: {
          sort: true,
        },
      },
      {
        name: dataTable[0].field5,
        label: t("App.homeDashboard.client.table.columns.column5"),
        options: {
          sort: true,
        },
      },
      {
        name: dataTable[0].field6,
        label: t("App.homeDashboard.client.table.columns.column6"),
        options: {
          sort: true,
        },
      },
      {
        name: dataTable[0].field7,
        label: t("App.homeDashboard.client.table.columns.column7"),
        options: {
          sort: true,
          hint: t("App.homeDashboard.client.table.columns.toolTip"),
          customBodyRender: (value) => {
            return (
              <Accordion
                disableGutters
                className="custom-accordion-summary"
                TransitionProps={{ unmountOnExit: true }}
                sx={{ width: "100%" }}
              >
                <AccordionSummary
                  className="custom-accordion-summary"
                  sx={{
                    "& .MuiAccordionSummary-content.MuiAccordionSummary-content":
                      {
                        display: "flex",
                        justifyContent: "flex-end",
                      },
                  }}
                  expandIcon={
                    value.length > 1 ? (
                      <ExpandMoreIcon
                        fontSize="large"
                        className="custom-accordion-summary__icon"
                      />
                    ) : (
                      <></>
                    )
                  }
                >
                  <label className="label-Primary">{value[0]}</label>
                </AccordionSummary>

                <AccordionDetails className="custom-accordion-detail">
                  {value.map((item, idx) => {
                    if (idx <= 4) {
                      return (
                        <label
                          key={idx}
                          className={
                            idx === 0
                              ? "custom-accordion-detail__hidden label-Primary"
                              : "label-Primary"
                          }
                        >
                          {item}
                        </label>
                      );
                    }
                  })}
                  {value.length > 4 && (
                    <>
                      <MoreHorizIcon
                        sx={{ color: "#8c8d9d" }}
                        fontSize="large"
                      />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          },
          customHeadLabelRender: (columnMeta) => {
            const upDates = (
              <div>
                <h4>{t("App.homeDashboard.client.table.columns.column7")}</h4>
                <h5 className="subheading-datatable">
                  {t("App.homeDashboard.client.table.columns.formatDate")}
                </h5>
              </div>
            );

            return upDates;
          },
        },
      },
    ];
  };

  /**
   * Funcon que sirve para settear los valores de las filas de la tabla de transmisiones
   * @param {obj} dataTable
   * @returns filas de la tabla de transmisiones
   */
  const buildDataTableRows = (dataTable) => {
    const raws = [];

    const formatMoney = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    });

    const formatDate = (date) => {
      const year = date.toString().slice(0, 4);
      const month = date.toString().slice(4, 6);
      const day = date.toString().slice(6, 8);

      const yearMonthDay = `${year}-${month}-${day}`;

      return yearMonthDay;
    };
    // console.clear();

    for (const item of dataTable) {
      const documentType =
        item.documentType === "Inventarios" ? "inventory" : "sales";
      if (documentType === selectedInformationType) {
        let obj = {
          [item.field1]: item.value1,
          [item.field2]: formatDate(item.value2),
          [item.field4]: isQuantitySelected
            ? item.value3
            : formatMoney.format(item.value4 / 1000000) + "M",
          [item.field5]: isQuantitySelected ? "N/A" : item.value5,
          [item.field6]: item.value6,
          [item.field7]: formatDates(item.value7),
        };

        raws.push(obj);
      }
    }

    return raws;
  };

  /**
   * Funcion que construye un nuevo objeto charts basado en los tipos de indicadores
   * @param {*} chartItems Indicadores items
   * @returns objeto formateado indicadores
   */
  const separateCharts = (chartItems) => {
    const mtdSales = [];

    const mtdInventories = [];

    const ytdSales = [];

    const ytdInventories = [];

    const vsInventories = [];

    const vsSales = [];

    const pies = {
      catalog: {},
      salesForce: {},
      assets: {},
    };

    chartItems.forEach((chartItem) => {
      if (chartItem.chartType.includes("MTD")) {
        if (["Barra", "KPI MTD"].includes(chartItem.chartDetail)) {
          chartItem.documentType.includes("Ventas")
            ? mtdSales.push(chartItem)
            : mtdInventories.push(chartItem);
        }
      }

      if (chartItem.chartType.includes("YTD")) {
        if (["Barra", "KPI YTD"].includes(chartItem.chartDetail)) {
          chartItem.documentType.includes("Ventas")
            ? ytdSales.push(chartItem)
            : ytdInventories.push(chartItem);
        }
      }

      if (
        chartItem.chartType.includes("Mes") ||
        chartItem.chartType.includes("vs")
      ) {
        if (["Barra", "KPI Mes"].includes(chartItem.chartDetail))
          chartItem.documentType.includes("Ventas")
            ? vsSales.push(chartItem)
            : vsInventories.push(chartItem);
      }

      if (chartItem.chartType.includes("Pie")) {
        if (chartItem.chartDetail.includes("Catálogo"))
          pies.catalog = chartItem;

        if (chartItem.chartDetail.includes("Fza")) pies.salesForce = chartItem;

        if (chartItem.chartDetail.includes("Comercios"))
          pies.assets = chartItem;
      }
    });

    return {
      mtdSales,
      ytdSales,
      vsSales,
      mtdInventories,
      ytdInventories,
      vsInventories,
      pies,
    };
  };

  /**
   * Funcion para construit objetos para renderizado de graficos de barras
   * @param {*} salesInfo Informacion de indicadores
   * @param {*} chartType Tipo de Chart
   * @returns Objeto formateado de charts
   */
  const buildInfoBarCharts = (salesInfo, chartType) => {
    let chartInfo = {};
    let quantityInfo = salesInfo.find(
      (item) => item.chartType === `${chartType} (Q)`
    );

    let valueInfo = salesInfo.find(
      (item) => item.chartType === `${chartType} ($)`
    );

    let kpi = salesInfo.find(
      (item) =>
        item.chartDetail.includes(chartType) || item.chartDetail.includes("KPI")
    );

    // NULL SAFETY
    // Protejo los tableros en caso de que este indicador no venga
    quantityInfo =
      quantityInfo === undefined ? emptyChartElement : quantityInfo;
    valueInfo = valueInfo === undefined ? emptyChartElement : valueInfo;
    kpi = kpi === undefined ? emptyChartElement : kpi;
    chartInfo.titleQuantity = quantityInfo.chartType;
    chartInfo.titleValue = valueInfo.chartType;
    chartInfo.month1 = quantityInfo.field1;
    chartInfo.month2 = quantityInfo.field2;
    chartInfo.resultMonth1Quantity = quantityInfo.value1 / 1000000;
    chartInfo.resultMonth2Quantity = quantityInfo.value2 / 1000000;
    chartInfo.resultMonth1Value = valueInfo.value1 / 1000000;
    chartInfo.resultMonth2Value = valueInfo.value2 / 1000000;
    chartInfo.kpiQuantity = kpi.field2;
    chartInfo.kpiValue = kpi.field1;
    chartInfo.resultKpiQuantity =
      parseFloat(kpi.value2 !== "∞" ? kpi.value2 : 0).toFixed(2) + " %";
    chartInfo.resultKpiValue =
      parseFloat(kpi.value1 !== "∞" ? kpi.value1 : 0).toFixed(2) + " %";
    return chartInfo;
  };

  const getTotal = (chartData) => {
    if (chartData.chartDetail.includes("Catálogo")) {
      setTotalCatalog(chartData.value1);
    } else if (chartData.chartDetail.includes("Fza")) {
      setTotalFZA(chartData.value1);
    } else {
      setTotalCommerce(chartData.value1);
    }
  };

  /**
   * Funcion para construir objetos para graficos de pastel
   * @param {*} chartData Informacion indicadores
   * @returns Objeto formateado para graficos de pastel
   */
  const buildPieChartsData = (chartData) => {
    getTotal(chartData);
    let field1 = [],
      field2 = [];
    // NULL SAFETY
    // Manejo en caso de que la información para los pies venga vacía
    if (!chartData.chartDetail) {
      chartData = emptyChartElement;
    }

    if (chartData.chartDetail.includes("Catálogo")) {
      field1.push(
        t("App.homeDashboard.client.donuts.legendsDonuts.donut1.legend1")
      );

      field2.push(
        t("App.homeDashboard.client.donuts.legendsDonuts.donut1.legend2")
      );
    } else if (chartData.chartDetail.includes("Fza")) {
      field1.push(
        t("App.homeDashboard.client.donuts.legendsDonuts.donut2.legend1")
      );

      field2.push(
        t("App.homeDashboard.client.donuts.legendsDonuts.donut2.legend2")
      );
    } else {
      field1.push(
        t("App.homeDashboard.client.donuts.legendsDonuts.donut3.legend1")
      );

      field2.push(
        t("App.homeDashboard.client.donuts.legendsDonuts.donut3.legend2")
      );
    }

    const template = {
      labels: chartData.chartDetail.includes("Comercios Activos")
        ? [
            `${field1 || "No se ha encontrado info"}: ${
              chartData.value2 || "0"
            }`,
            `${field2 || "No se ha encontrado info"}: ${
              chartData.value3 || "0"
            }`,
          ]
        : [
            `${field1 || "No se ha encontrado info"}: ${
              chartData.value3 || "0"
            }`,
            `${field2 || "No se ha encontrado info"}: ${
              chartData.value2 || "0"
            }`,
          ],
      datasets: [
        {
          data: chartData.chartDetail.includes("Comercios Activos")
            ? [
                parseFloat(chartData.value2 * 100).toFixed(2),
                parseFloat(chartData.value3 * 100).toFixed(2),
              ]
            : [
                parseFloat(chartData.value3 * 100).toFixed(2),
                parseFloat(chartData.value2 * 100).toFixed(2),
              ],
          backgroundColor: chartColors,
          hoverOffset: 2,
        },
      ],
    };

    return template;
  };

  /**
   * Funcion para el formato de fechas
   * @param {*} dates fechas a formatear
   * @returns Objeto de las fechas con el formato
   */
  const formatDates = (dates) => {
    const formattedDates = [];
    const arrayTransformDate = JSON.parse(dates.replace(/["']+/g, ""));

    for (const date of arrayTransformDate) {
      const stringDate = date.toString();

      const formatDate = moment(stringDate, "YYYYMMDD").format("YYYY-MM-DD");
      formattedDates.push(formatDate);
    }

    let orderedDates = formattedDates.sort((a, b) =>
      moment(b, "YYYY-MM-DD").diff(moment(a, "YYYY-MM-DD"))
    );
    return orderedDates;
  };

  /**
   * Handles changes basado en la informacion de informationType field selection.
   * Sirve para modificar el dashboard dependiendo de la seleccion.
   * @param {*} e event que contiene el valor seleccionado
   */
  const handleFilterSelectedInformationType = (e) => {
    const value = e.target.value;
    if (value !== "sales") setIsSalesSelected(false);
    if (value === "sales") setIsSalesSelected(true);
    setSelectedInformationType(value);
    setSelectType(true);
  };

  /**
   * Handles changes basado en la informacion de selectedConsultation field selection.
   * Sirve para modificar el dashboard dependiendo de la seleccion.
   * @param {*} e event que contiene el valor seleccionado
   */
  const handleFilterSelectedConsultationUnit = (e) => {
    const value = e.target.value;
    if (value === "value") {
      setIsQuantitySelected(false);
    } else {
      setIsQuantitySelected(true);
    }

    setSelectedConsultationUnit(value);

    //if (!isSalesSelected) setSelectedConsultationUnitInventory(value);
  };

  /**
   * Modifica la tabla cuando el usuario seleccione unidad de consulta
   */
  useEffect(() => {
    if (dataTable) {
      setDataTableStructureInfo(dataTable);
    }
  }, [selectedConsultationUnit]);

  /**
   * Modifica la tabla cuando el usuario seleccione tipo de información
   */
  useEffect(() => {
    if (selectType && dataTable) {
      setDataTableStructureInfo(dataTable);
      setSelectType(false);
    }
  }, [selectType]);

  const dataTemplate = {
    labels: ["", ""],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: chartColors,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },

      tooltip: {
        callbacks: {
          label: function (context) {
            let value = context.dataset.data[context.dataIndex].toFixed(3);

            return value + "M";
          },
        },
      },
    },

    scales: {
      x: {
        ticks: {
          callback: function (value) {
            return value + "M";
          },
        },
      },
    },
  };

  const pieOptions = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          boxHeight: 10,
          textAlign: "left",
          //Color de la letra
          color: "$very_dark_grayish_blue",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  /**
   * Default mtdBarSales values
   */
  const mtdBarSales = {
    labels: [dataBarChartMTDSales?.month1, dataBarChartMTDSales?.month2],
    datasets: [
      {
        label: "",
        data: [
          !isQuantitySelected
            ? dataBarChartMTDSales?.resultMonth1Value
            : dataBarChartMTDSales?.resultMonth1Quantity,
          !isQuantitySelected
            ? dataBarChartMTDSales?.resultMonth2Value
            : dataBarChartMTDSales?.resultMonth2Quantity,
        ],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Default mtdBarInventory values
   */
  const mtdBarInventory = {
    labels: [
      dataBarChartMTDInventory?.month1,
      dataBarChartMTDInventory?.month2,
    ],
    datasets: [
      {
        label: "",
        data: [
          !isQuantitySelected
            ? dataBarChartMTDInventory?.resultMonth1Value
            : dataBarChartMTDInventory?.resultMonth1Quantity,
          !isQuantitySelected
            ? dataBarChartMTDInventory?.resultMonth2Value
            : dataBarChartMTDInventory?.resultMonth2Quantity,
        ],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Default ytdBarSales values
   */
  const ytdBarSales = {
    labels: [dataBarChartYTDSales?.month1, dataBarChartYTDSales?.month2],
    datasets: [
      {
        label: "",
        data: [
          !isQuantitySelected
            ? dataBarChartYTDSales?.resultMonth1Value
            : dataBarChartYTDSales?.resultMonth1Quantity,
          !isQuantitySelected
            ? dataBarChartYTDSales?.resultMonth2Value
            : dataBarChartYTDSales?.resultMonth2Quantity,
        ],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Default ytdBarInventory values
   */
  const ytdBarInventory = {
    labels: [
      dataBarChartYTDInventory?.month1,
      dataBarChartYTDInventory?.month2,
    ],
    datasets: [
      {
        label: "",
        data: [
          !isQuantitySelected
            ? dataBarChartYTDInventory?.resultMonth1Value
            : dataBarChartYTDInventory?.resultMonth1Quantity,
          !isQuantitySelected
            ? dataBarChartYTDInventory?.resultMonth2Value
            : dataBarChartYTDInventory?.resultMonth2Quantity,
        ],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Default vsBarSales values
   */
  const vsBarSales = {
    labels: [dataBarChartVSSales?.month1, dataBarChartVSSales?.month2],
    datasets: [
      {
        label: "",
        data: [
          !isQuantitySelected
            ? dataBarChartVSSales?.resultMonth1Value
            : dataBarChartVSSales?.resultMonth1Quantity,
          !isQuantitySelected
            ? dataBarChartVSSales?.resultMonth2Value
            : dataBarChartVSSales?.resultMonth2Quantity,
        ],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Default vsBarInventory values
   */
  const vsBarInventory = {
    labels: [dataBarChartVSInventory?.month1, dataBarChartVSInventory?.month2],
    datasets: [
      {
        label: "",
        data: [
          !isQuantitySelected
            ? dataBarChartVSInventory?.resultMonth1Value
            : dataBarChartVSInventory?.resultMonth1Quantity,
          !isQuantitySelected
            ? dataBarChartVSInventory?.resultMonth2Value
            : dataBarChartVSInventory?.resultMonth2Quantity,
        ],
        backgroundColor: chartColors,
      },
    ],
  };

  /**
   * Transmitions datatable values
   */
  const optionsDataTable = {
    download: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    filter: false,
    rowHover: false,
    filterType: "dropdown",
    responsive: "standard",
    searchPlaceholder: t("App.salesForce.placeholder"),
    elevation: 0,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: t("App.validationMessages.noMatchFilter"),
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  /**
   * Handle que sirve para definir las acciones del Joyride dependiendo de la seleccion del usuario
   * @param {*} data
   */
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsOpenTour(false);
    }
  };

  /**
   * Funcion para filtrar los indicadores dependiendo del pais y el ean de una empresa
   * @param {Object} data
   */
  const handleFilters = async (data) => {
    try {
      setSetDataFilter(data);
      const obj = {
        eanProvider: data.company.eanCompany,
        country: data.company.country,
      };
      setIsLoading(true);
      verificarYRedirigir(userInfo);
      const indicatorsChartService = await IndicatorsChartHome(obj);
      const listTransmissionsService = await ListTransmissionsTable(obj);
      const indicatorsInfoService = await GetIndicators({
        eanProvider: data.company.eanCompany,
        indicatorType: "home",
      });
      // Obtener links de qlik para las opciones de menú de acuerdo a la empresa
      // seleccionada en el filtro
      getUrlsQlikMenuOptions(indicatorsInfoService?.data?.responseMessage);
      processDataAndSetCharts(indicatorsChartService);
      // Obtener los links de qliks correspondites para los botones del
      // home de las gráficas y de auditoría de transmisión
      setQlikLinksButtons(indicatorsInfoService?.data?.responseMessage);
      setDataTable(listTransmissionsService.data.responseMessage);
      setDataTableStructureInfo(listTransmissionsService.data.responseMessage);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Funcion para obtener los links de Qlik de la empresa
   * consultada para las opciones de menú y guardarlos en el sessionStorage
   * @param {Array} data informacion de los indicadores
   */
  const getUrlsQlikMenuOptions = (data) => {
    // opciones de menú con url de qlik dinámica
    const optionsDynamicUrlQlik = ["reporte en línea", "preguntas de negocio"];
    // nombre de las opciones de menú para
    // para guardar la url en el session storage
    const nameEnglishOptions = {
      "reporte en línea": "onlineReport",
      "preguntas de negocio": "bussinessQuestion",
    };
    let url = "";
    data.map((item) => {
      url = item.url ?? "";
      let optionName = item.name.toLowerCase();
      if (optionsDynamicUrlQlik.includes(optionName)) {
        sessionStorage.setItem(nameEnglishOptions[optionName], url);
      }
    });
  };

  /**
   *  Estructurar la información de la tabla
   */
  const setDataTableStructureInfo = (data) => {
    setIsLoadingTable(true);
    let columns = [];
    let rows = [];

    if (data.length > 0) {
      columns = buildDataTableColumns(data);
      rows = buildDataTableRows(data);
    }

    setDataTableColumns(columns);
    setDataTableRaws(rows);
    setIsLoadingTable(false);
  };

  /**
   * Busca los links de qlik para los botones  de las
   * gráficas de donas y de auditoría de transmisión
   * @param {} data arreglo de indicadores de home
   */
  const setQlikLinksButtons = (data) => {
    let finalMap = new Map();
    const catalogLink = data.find(
      (item) => item?.name.toLowerCase() === "productos con categoría faltante"
    );

    const salesForceLink = data.find(
      (item) => item?.name.toLowerCase() === "punto de venta sin vendedor"
    );
    const commerceLink = data.find(
      (item) => item?.name.toLowerCase() === "total comercios"
    );

    const transmissionAudLink = data.find(
      (item) => item?.name.toLowerCase() === "transmission_audit"
    );
    finalMap.set("catalog", catalogLink ?? "");
    finalMap.set("salesforce", salesForceLink ?? "");
    finalMap.set("commerce", commerceLink ?? "");
    finalMap.set("transmissionAudit", transmissionAudLink ?? "");

    setQlikLinks(finalMap);
  };

  /**
   * Joyride steps as html elements
   */
  const steps = [
    {
      target: ".step-1",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title1")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 1/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content1")}
          </p>
        </div>
      ),
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".step-2",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title2")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 2/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content2")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".step-3",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title3")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 3/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content3")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-0",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title4")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 4/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content4")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-1",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title5")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 5/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content5")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "#menu-options-2",
      placement: "right",
      content: (
        <div className="container-joyride">
          <h2 className="title-joyride">
            {t("App.homeDashboard.coachmark.titles.title6")}
          </h2>
          <h4 className="steps-joyride">
            {t("App.homeDashboard.coachmark.step")} 6/6
          </h4>
          <p className="parragraph-joyride">
            {t("App.homeDashboard.coachmark.contents.content6")}
          </p>
        </div>
      ),
      disableBeacon: true,
    },
  ];

  return (
    <Box className="box-container">
      {userInfo?.company?.eanCompany !== "CASSANDRA" && (
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          paddingBottom={3}
        >
          <Grid item>
            <label className="display-large__primary-one">
              {t("App.homeDashboard.client.welcomeTitle")}
            </label>
            <IconButton aria-label="delete" onClick={() => setIsOpenTour(true)}>
              <TourIcon fontSize="large" className="heading__primary-color" />
            </IconButton>
            <Joyride
              callback={handleJoyrideCallback}
              run={isOpenTour}
              continuous={true}
              showSkipButton={true}
              hideCloseButton={true}
              steps={steps}
              locale={{
                skip: (
                  <Button variant="text" className="button-skip">
                    {t("App.homeDashboard.coachmark.buttons.skip")}
                  </Button>
                ),
                next: (
                  <Button
                    startIcon={<ArrowForwardOutlinedIcon />}
                    className="button-next"
                  >
                    {t("App.homeDashboard.coachmark.buttons.next")}
                  </Button>
                ),
                back: (
                  <Button className="button-back" variant="outlined">
                    {t("App.homeDashboard.coachmark.buttons.back")}
                  </Button>
                ),
                last: (
                  <Button className="button-last">
                    {t("App.homeDashboard.coachmark.buttons.finish")}
                  </Button>
                ),
              }}
              styles={{
                arrowColor: "#fff",
                backgroundColor: "#fff",
                beaconSize: 36,
                overlayColor: "rgba(0, 0, 0, 0.5)",
                primaryColor: "#f04",
                spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
                textColor: "#333",
                width: undefined,
                zIndex: 100,
              }}
            />
          </Grid>

          <Grid item lg={8} md={8} xs={12} sm={12} justifyContent="flex-end">
            <Grid container spacing={2} justifyContent={"flex-end"}>
              <IndicatorsCards
                excludeHome={"prescriptiva"}
                setIsLoadingButtons={setIsLoadingButtons}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        sx={{
          width: "100%",
          padding: "1%",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <FilterBusinessGroup
          title={t("App.manageBusinessGroup.filter.title2")}
          handleRequestIndicators={handleFilters}
          setIsLoading={setIsLoading}
        />
      </Grid>
      {userInfo?.company?.eanCompany === "CASSANDRA" ||
      userInfo?.company?.typeCompany === "C" ? (
        <WelcomeHome />
      ) : isLoading || isLoadingButtons ? (
        <CustomSkeleton />
      ) : chartsInfo.length > 0 ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} lg={8}>
              <Card
                style={{
                  borderRadius: "16px",
                  boxShadow: "none",
                }}
              >
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} lg={6}>
                      <label className="subheading__text-black">
                        {t("App.homeDashboard.client.typeInformation")}
                      </label>
                      <Grid>
                        <Radio
                          checked={selectedInformationType === "sales"}
                          onChange={handleFilterSelectedInformationType}
                          value="sales"
                          name="infoTypeSales"
                        />
                        <label className="subheading__text-grey">
                          {t(
                            "App.homeDashboard.client.filters.informationType.sales"
                          )}
                        </label>
                      </Grid>
                      <Grid>
                        <Radio
                          checked={selectedInformationType === "inventory"}
                          onChange={handleFilterSelectedInformationType}
                          value="inventory"
                          name="infoTypeInventory"
                        />
                        <label className="subheading__text-grey">
                          {t(
                            "App.homeDashboard.client.filters.informationType.inventory"
                          )}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                      <label className="subheading__text-black">
                        {t("App.homeDashboard.client.queryUnit")}
                      </label>
                      <Grid>
                        <Radio
                          checked={selectedConsultationUnit === "quantity"}
                          onChange={handleFilterSelectedConsultationUnit}
                          value="quantity"
                          name="consultationUnitQuantity"
                        />
                        <label className="subheading__text-grey">
                          {t(
                            "App.homeDashboard.client.filters.consultationUnit.sales.sellOutQuantity"
                          )}
                        </label>
                      </Grid>
                      <Grid>
                        <Radio
                          checked={selectedConsultationUnit === "value"}
                          onChange={handleFilterSelectedConsultationUnit}
                          value="value"
                          name="consultationUnitValue"
                        />
                        <label className="subheading__text-grey">
                          {t(
                            "App.homeDashboard.client.filters.consultationUnit.sales.sellOutValue"
                          )}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card
                style={{
                  borderRadius: "16px",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-text-align-right card-text-align-center">
                  <Grid container spacing={1.9}>
                    <Grid item xs={12}>
                      <label className="display-large__primary-one">
                        {t("App.homeDashboard.client.lastUpdate")}
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                      <label className="subheading__text-black-2">
                        {t(
                          "App.homeDashboard.client.filters.informationType.sales"
                        )}
                        : {moment(lastUpdateSales).format("YYYY-MM-DD")}
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={12}>
                      <label className="subheading__text-black-2">
                        {t(
                          "App.homeDashboard.client.filters.informationType.inventory"
                        )}
                        : {moment(lastUpdateInventories).format("YYYY-MM-DD")}
                      </label>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card style={{ borderRadius: "16px", boxShadow: "none" }}>
                <CardContent className="card-content">
                  <Box className="box-content__doughnut">
                    <label className="subheading__text-black">
                      {t("App.homeDashboard.client.donuts.catalog")}
                    </label>
                    <Grid className="donut-container">
                      <Doughnut
                        data={catalogChart || dataTemplate}
                        options={pieOptions}
                      />
                      <Grid className="doughnut-total-text__container">
                        <label className="doughnut-total-text">
                          {t(
                            "App.homeDashboard.client.donuts.legendsDonuts.donut1.legend3"
                          )}
                          {": "}
                          {totalCatalog}
                        </label>
                      </Grid>
                    </Grid>
                    <Button
                      className="button-charts"
                      startIcon={<KeyboardArrowRightIcon />}
                      onClick={() =>
                        openQlikSense(
                          fullNameUser,
                          emailUser,
                          qlikLinks.get("catalog")?.url
                        )
                      }
                    >
                      {t(
                        "App.homeDashboard.client.donuts.buttonsDonuts.button1"
                      )}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card
                style={{
                  borderRadius: "16px",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-content">
                  <Box className="box-content__doughnut">
                    <label className="subheading__text-black">
                      {t("App.homeDashboard.client.donuts.salesForce")}
                    </label>
                    <Grid className="donut-container">
                      <Doughnut
                        data={salesForceChart || dataTemplate}
                        options={pieOptions}
                      />
                      <Grid className="doughnut-total-text__container">
                        <label className="doughnut-total-text">
                          {t(
                            "App.homeDashboard.client.donuts.legendsDonuts.donut2.legend3"
                          )}
                          {": "}
                          {totalFZA}
                        </label>
                      </Grid>
                    </Grid>
                    <Button
                      className="button-charts"
                      startIcon={<KeyboardArrowRightIcon />}
                      onClick={() =>
                        openQlikSense(
                          fullNameUser,
                          emailUser,
                          qlikLinks.get("salesforce")?.url
                        )
                      }
                    >
                      {t(
                        "App.homeDashboard.client.donuts.buttonsDonuts.button2"
                      )}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card
                style={{
                  borderRadius: "16px",
                  boxShadow: "none",
                }}
              >
                <CardContent className="card-content">
                  <Box className="box-content__doughnut">
                    <label className="subheading__text-black">
                      {t("App.homeDashboard.client.donuts.activeRetailers")}
                    </label>
                    <Grid className="donut-container">
                      <Doughnut
                        data={assetsChart || dataTemplate}
                        options={pieOptions}
                      />
                      <Grid className="doughnut-total-text__container">
                        <label className="doughnut-total-text">
                          {t(
                            "App.homeDashboard.client.donuts.legendsDonuts.donut3.legend3"
                          )}
                          {": "}
                          {totalCommerce}
                        </label>
                      </Grid>
                    </Grid>
                    <Button
                      className="button-charts"
                      startIcon={<KeyboardArrowRightIcon />}
                      onClick={() => setOpenActiveCommerces(true)}
                    >
                      {t(
                        "App.homeDashboard.client.donuts.buttonsDonuts.button3"
                      )}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              {dataBarChartMTDSales && (
                <Card
                  style={{
                    borderRadius: "16px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent className="card-content">
                    <Box className="box-content__bar">
                      <label className="subheading__text-black">
                        {t("App.homeDashboard.client.barGraphic.monthToDate")}{" "}
                        {isQuantitySelected
                          ? t("App.homeDashboard.client.barGraphic.quantity")
                          : t("App.homeDashboard.client.barGraphic.value")}
                      </label>

                      <Bar
                        options={options}
                        data={isSalesSelected ? mtdBarSales : mtdBarInventory}
                      />
                      {isQuantitySelected ? (
                        <p
                          className={`${
                            parseFloat(
                              isSalesSelected
                                ? dataBarChartMTDSales?.resultKpiQuantity
                                : dataBarChartMTDInventory?.resultKpiQuantity
                            ) < 0
                              ? "error"
                              : "success"
                          } card-text-align-right  subheading__text-black`}
                        >
                          {isSalesSelected
                            ? dataBarChartMTDSales?.resultKpiQuantity
                            : dataBarChartMTDInventory?.resultKpiQuantity}
                        </p>
                      ) : (
                        <p
                          className={`${
                            parseFloat(
                              isSalesSelected
                                ? dataBarChartMTDSales?.resultKpiValue
                                : dataBarChartMTDInventory?.resultKpiValue
                            ) < 0
                              ? "error"
                              : "success"
                          } card-text-align-right  subheading__text-black`}
                        >
                          {isSalesSelected
                            ? dataBarChartMTDSales?.resultKpiValue
                            : dataBarChartMTDInventory?.resultKpiValue}
                        </p>
                      )}

                      <p className="card-text-align-right  subheading__text-grey">
                        {isQuantitySelected
                          ? t("App.homeDashboard.client.card.mtdVariationUnit")
                          : t(
                              "App.homeDashboard.client.card.mtdVariationValue"
                            )}
                      </p>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              {dataBarChartYTDSales && (
                <Card
                  style={{
                    borderRadius: "16px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent className="card-content">
                    <Box className="box-content__bar">
                      <label className="subheading__text-black">
                        {t("App.homeDashboard.client.barGraphic.yearToDate")}{" "}
                        {isQuantitySelected
                          ? t("App.homeDashboard.client.barGraphic.quantity")
                          : t("App.homeDashboard.client.barGraphic.value")}
                      </label>

                      <Bar
                        options={options}
                        data={isSalesSelected ? ytdBarSales : ytdBarInventory}
                      />
                      {isQuantitySelected ? (
                        <p
                          className={`${
                            parseFloat(
                              isSalesSelected
                                ? dataBarChartYTDSales?.resultKpiQuantity
                                : dataBarChartYTDInventory?.resultKpiQuantity
                            ) < 0
                              ? "error"
                              : "success"
                          } card-text-align-right  subheading__text-black`}
                        >
                          {isSalesSelected
                            ? dataBarChartYTDSales?.resultKpiQuantity
                            : dataBarChartYTDInventory?.resultKpiQuantity}
                        </p>
                      ) : (
                        <p
                          className={`${
                            parseFloat(
                              isSalesSelected
                                ? dataBarChartYTDSales?.resultKpiValue
                                : dataBarChartYTDInventory?.resultKpiValue
                            ) < 0
                              ? "error"
                              : "success"
                          } card-text-align-right  subheading__text-black`}
                        >
                          {isSalesSelected
                            ? dataBarChartYTDSales?.resultKpiValue
                            : dataBarChartYTDInventory?.resultKpiValue}
                        </p>
                      )}

                      <p className="card-text-align-right  subheading__text-grey">
                        {isQuantitySelected
                          ? t("App.homeDashboard.client.card.ytdVariationUnit")
                          : t(
                              "App.homeDashboard.client.card.ytdVariationValue"
                            )}
                      </p>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              {dataBarChartVSSales && (
                <Card
                  style={{
                    borderRadius: "16px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent className="card-content">
                    <Box className="box-content__bar">
                      <label className="subheading__text-black">
                        {t(
                          "App.homeDashboard.client.barGraphic.currentMonthVsPrevious"
                        )}{" "}
                        {isQuantitySelected
                          ? t("App.homeDashboard.client.barGraphic.quantity")
                          : t("App.homeDashboard.client.barGraphic.value")}
                      </label>

                      <Bar
                        options={options}
                        data={isSalesSelected ? vsBarSales : vsBarInventory}
                      />
                      {isQuantitySelected ? (
                        <p
                          className={`${
                            parseFloat(
                              isSalesSelected
                                ? dataBarChartVSSales?.resultKpiQuantity
                                : dataBarChartVSInventory?.resultKpiQuantity
                            ) < 0
                              ? "error"
                              : "success"
                          } card-text-align-right  subheading__text-black`}
                        >
                          {isSalesSelected
                            ? dataBarChartVSSales?.resultKpiQuantity
                            : dataBarChartVSInventory?.resultKpiQuantity}
                        </p>
                      ) : (
                        <p
                          className={`${
                            parseFloat(
                              isSalesSelected
                                ? dataBarChartVSSales?.resultKpiValue
                                : dataBarChartVSInventory?.resultKpiValue
                            ) < 0
                              ? "error"
                              : "success"
                          } card-text-align-right  subheading__text-black`}
                        >
                          {isSalesSelected
                            ? dataBarChartVSSales?.resultKpiValue
                            : dataBarChartVSInventory?.resultKpiValue}
                        </p>
                      )}

                      <p className="card-text-align-right  subheading__text-grey">
                        {isQuantitySelected
                          ? t("App.homeDashboard.client.card.variationUnit")
                          : t("App.homeDashboard.client.card.variationValue")}
                      </p>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="audit-transmission">
                <label className="display-large__primary-one home-title">
                  {t("App.homeDashboard.client.table.transmissionsStatus")}
                </label>
                <div className="div-transmission-right">
                  <p className="subheading__text-black home-text">
                    {t("App.homeDashboard.client.table.paragraph")}
                  </p>
                  <Button
                    onClick={() =>
                      openQlikSense(
                        fullNameUser,
                        emailUser,
                        qlikLinks.get("transmissionAudit")?.url
                      )
                    }
                    sx={{ padding: "12px 24px" }}
                    className="button-transmission"
                    startIcon={<KeyboardArrowRightIcon />} // HHHEEEEEEEEEEEREEEE
                  >
                    {t("App.homeDashboard.client.table.buttonTransmission")}
                  </Button>
                </div>
              </div>

              {!isLoadingTable && (
                <Grid style={{ width: "100%" }}>
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        className="body-dataTable"
                        data={dataTableRaws}
                        columns={dataTableColumns}
                        options={optionsDataTable}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <NoInfoDashboard />
      )}
      <ModalActiveCompanies
        open={openActiveCommerces}
        setOpen={setOpenActiveCommerces}
        userInfo={userInfo}
        infoChart={assetsChart}
      />
    </Box>
  );
};
