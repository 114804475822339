import { React, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ModalCreateRoles from "../../components/common/modalCreateRoles";
import { GetRoleList } from "../../services/admin/roleServices";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CODES } from "../../consts/codes";
import IconButton from "@mui/material/IconButton";
import { FilterRoles } from "../../components/common/filterRoles";
import { ModalDeleteRole } from "../../components/common/modalDeleteRole";
import ModalEditRoles from "../../components/common/modalEditRoles";
import ModalInfo from "../../components/common/Modals/modalInfo";
import Skeleton from "@mui/material/Skeleton";
import NoMatchImage from "../../components/common/NoMatchImage/noMatchImage";
import { NoRoleComponent } from "../../components/common/noImages/noRoleComponent";
import { getUser } from "../../pages/userSlice";
import { useSelector } from "react-redux";
import { GetActiveProduct } from "../../services/admin/adminServices";

export const ListRoles = (props) => {
  const [data, setData] = useState([]);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshFilters, setRefreshFilters] = useState(false);
  const [foundResults, setFoundResults] = useState(null);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [isOpenCreateRol, setIsOpenCreateRol] = useState(false);
  const [noRoles, setNoRoles] = useState(false);
  const [createRolResponseData, setCreateRolResponseData] = useState({});
  const { t } = useTranslation();
  const { companyId } = useParams();
  // comment
  //No Filter Found
  const [filterNotFound, setFilterNotFound] = useState(false);

  const initialFormState = {
    idRole: "",
    idMenuOption: "",
    nameRole: "",
    descriptionRole: "",
    active: "",
  };

  const [currentRole, setCurrentRole] = useState(initialFormState);

  const editRow = (role) => {
    setCurrentRole({
      idRole: role.idRole,
      idMenuOption: role.idMenuOption,
      nameRole: role.nameRole,
      descriptionRole: role.descriptionRole,
      active: role.active,
    });
  };

  const columns = [
    {
      name: "product",
      label: t("App.listRoles.product"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("App.listRoles.role"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: t("App.listRoles.description"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "clientType",
      label: t("App.listRoles.clientType"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "status",
      label: t("App.listRoles.status"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "delete",
      label: t("App.listRoles.delete"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "edit",
      label: t("App.listRoles.edit"),
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    print: false,
    filter: false,
    download: false,
    search: false,
    selectableRows: "none",
    responsive: "simple",
    searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
    elevation: 0,
    viewColumns: false,

    searchOpen: false,
    rowsPerPageOptions: [10, 15, 20],
    textLabels: {
      body: {
        noMatch: <NoMatchImage />,
        toolTip: "Sort",

        columnHeaderTooltip: (column) =>
          t("App.buttonLabels.order", { label: column.label }),
      },
      pagination: {
        next: t("App.userDetail.next"),
        previous: t("App.userDetail.previous"),
        rowsPerPage: t("App.userDetail.resultsPerPage"),
        displayRows: t("App.userDetail.of"),
      },
    },
  };

  useEffect(() => {
    let mounted = true;

    async function fetchData() {
      try {
        if (mounted) {
          const roleService = await GetRoleList();

          if (roleService.status === CODES.COD_RESPONSE_HTTP_OK) {
            setLoading(false);

            if (
              roleService.data.responseCode ===
              CODES.COD_RESPONSE_SUCCESS_REQUEST
            ) {
              const responseMessage = roleService.data.responseMessage;

              if (responseMessage.length === 0) {
                setNoRoles(true);
              }

              // console.log(responseMessage);
              const ProcessRows = responseMessage.map((item) => {
                return {
                  product: !item.menuOptionName ? " - " : item.menuOptionName,
                  name: !item.nameRole ? " - " : item.nameRole,
                  description: !item.descriptionRole
                    ? " - "
                    : item.descriptionRole,
                  status: item.state ? (
                    <p className="state-colors__primary-color-one">
                      {t("App.userDetail.filter.active")}
                    </p>
                  ) : (
                    <p className="state-colors__tertiary-color-one">
                      {t("App.userDetail.filter.inactive")}
                    </p>
                  ),
                  clientType:
                    item.clientType === "SI"
                      ? t("App.listRoles.yes")
                      : t("App.listRoles.no"),
                  edit: (
                    <IconButton
                      component="span"
                      onClick={() => {
                        editRow(item);
                      }}
                    >
                      <ModalEditRoles
                        refreshFilters={refreshFilters}
                        setRefreshFilters={setRefreshFilters}
                        currentRole={item}
                        modified={modified}
                        setModified={setModified}
                        setResponseData={setCreateRolResponseData}
                        openModal={setIsOpenCreateRol}
                      />
                    </IconButton>
                  ),
                  delete: (
                    <ModalDeleteRole
                      refreshFilters={refreshFilters}
                      setRefreshFilters={setRefreshFilters}
                      modified={modified}
                      setModified={setModified}
                      role={item}
                    />
                  ),
                };
              });
              setUnfilteredData(ProcessRows);
              setFoundResults(true);
              setData(ProcessRows);
            }
          } else {
            setData([]);
            setFilterNotFound(true);
          }
        }
      } catch (error) {
        console.log("====================================");
        console.log(error);
        console.log("====================================");
      }
    }
    fetchData();

    return () => {
      mounted = false;
    };
  }, [modified]);

  const loader = () => {
    const options = {
      filterType: "dropdown",
      print: false,
      filter: false,
      download: false,
      search: false,
      selectableRows: "none",
      responsive: "standard",
      searchPlaceholder: t("App.userDetail.searchPlaceHolder"),
      elevation: 0,
      viewColumns: false,

      searchOpen: false,
      rowsPerPageOptions: [10, 15, 20],
      textLabels: {
        body: {
          noMatch: <Skeleton />,
          toolTip: "Sort",

          columnHeaderTooltip: (column) =>
            t("App.buttonLabels.order", { label: column.label }),
        },
        pagination: {
          next: t("App.userDetail.next"),
          previous: t("App.userDetail.previous"),
          rowsPerPage: t("App.userDetail.resultsPerPage"),
          displayRows: t("App.userDetail.of"),
        },
      },
    };

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap={2}
        style={{ padding: "1rem" }}
      >
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.listRoles.title")}
          </p>
        </Grid>

        <Grid className="modalCreateUsers-principalBox">
          <ModalCreateRoles
            companyId={companyId}
            setResponseData={setCreateRolResponseData}
            openModal={setIsOpenCreateRol}
            modified={modified}
            setModified={setModified}
            refreshFilters={refreshFilters}
            setRefreshFilters={setRefreshFilters}
          />
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Accordion
            sx={{
              boxShadow: "none" ,                    
                ".MuiAccordionSummary-root.Mui-expanded": {
                  border:" 1px solid #c0c1d8 !important",
                  borderRadius:" 8px 8px 0px 0px !important",  
                  boxShadow: "none !important",
                },
            }}>
            <AccordionSummary
              sx={{
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.20) !important",
              }}
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                  transform: "rotate(90deg)", 
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterRoles
                unfilteredData={unfilteredData}
                setData={setData}
                setFoundResults={setFoundResults}
                editRow={editRow}
                modified={modified}
                setModified={setModified}
                refreshFilters={refreshFilters}
                setRefreshFilters={setRefreshFilters}
                setFilterNotFound={setFilterNotFound}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <MUIDataTable
            className="dataTable"
            data={data}
            columns={columns}
            options={options}
          />
        </Grid>

        <ModalInfo
          title={" "}
          responseData={createRolResponseData}
          open={isOpenCreateRol}
          onClose={() => {
            setIsOpenCreateRol(false);
            setCreateRolResponseData({});
          }}
        />
      </Grid>
    );
  };

  if (loading) {
    return loader();
  } else {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        gap={4}
        style={{ padding: "1rem" }}
      >
        <Grid item>
          <p className="display-large__primary-one">
            {t("App.listRoles.title")}
          </p>
        </Grid>

        <Grid className="modalCreateUsers-principalBox">
          <ModalCreateRoles
            companyId={companyId}
            setResponseData={setCreateRolResponseData}
            openModal={setIsOpenCreateRol}
            modified={modified}
            setModified={setModified}
            refreshFilters={refreshFilters}
            setRefreshFilters={setRefreshFilters}
            setFilterNotFound={setFilterNotFound}
          />
        </Grid>

        <Grid item style={{ width: "100%" }}>
          <Accordion 
            sx={{
              boxShadow: "none" ,                    
                ".MuiAccordionSummary-root.Mui-expanded": {
                  border:" 1px solid #c0c1d8 !important",
                  borderRadius:" 8px 8px 0px 0px !important",  
                  boxShadow: "none !important",
                },
            }}
           >
            <AccordionSummary  
            sx={{
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.20) !important",
            }}
              expandIcon={
                <ExpandMoreIcon sx={{ fontSize: 20, color: "#543ab4" }} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <TuneOutlinedIcon
                sx={{
                  fontSize: 20,
                  color: "#543ab4",
                  transform: "rotate(90deg)",
                }}
              />

              <p className="navbar__text " style={{ paddingLeft: "1rem" }}>
                {t("App.listUsers.filter")}
              </p>
            </AccordionSummary>

            <AccordionDetails>
              <FilterRoles
                unfilteredData={unfilteredData}
                setData={setData}
                setFoundResults={setFoundResults}
                editRow={editRow}
                modified={modified}
                setModified={setModified}
                refreshFilters={refreshFilters}
                setRefreshFilters={setRefreshFilters}
                setFilterNotFound={setFilterNotFound}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item 
          sx={{ 
            width: "100%",
            padding: "2rem",
            backgroundColor: "white",
            borderRadius: "8px",
          }}>
          {!noRoles ? (
            !filterNotFound ? (
              <MUIDataTable
                className="dataTable__base"
                data={data}
                columns={columns}
                options={options}
              />
            ) : (
              <NoMatchImage />
            )
          ) : (
            <Grid container className="col-flex">
              <NoRoleComponent />
            </Grid>
          )}
        </Grid>

        <ModalInfo
          title={""}
          responseData={createRolResponseData}
          open={isOpenCreateRol}
          onClose={() => {
            setIsOpenCreateRol(false);
          }}
        />
      </Grid>
    );
  }
};
