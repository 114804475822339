import { Grid } from "@mui/material";
import { t } from "i18next";
import React from "react";

export const NoRoleComponent = (props) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className="u-marginB u-marginT"
      spacing={2}
    >
      <Grid item className="text-center">
        <img
          className="img__noInfo"
          src={`/assets/components/noRoleImage.svg`}
        />
      </Grid>
      <Grid item className="text-center">
        <p className="display-large__primary-three text-center">
          {t("App.listRoles.noRoleTitle")}
        </p>
      </Grid>
      <Grid item>
        <p className="subheading__text-grey text-center wrap-text__large">
          {t("App.listRoles.noRoleText")}
        </p>
      </Grid>
    </Grid>
  );
};
